/* Imgcategory-module start */

.imgcategory {
  position: relative;
  margin-top: 10px;
  margin-bottom: 24px;
  z-index: 1;

  &__title {
    color: #000;
    text-align: center;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 1;
    text-transform: uppercase;
  }

  &__subtitle {
    color: #f37a45;
    text-align: center;
    font-family: 'Copyright House Industries', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: normal;
  }

  &__swiper {
    margin: 5px -6px -30px;
    padding: 155px 6px 30px;
    overflow: hidden;
  }

  &__category {
    position: relative;
    padding: 127px 35px 27px 40px;
    min-height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    text-align: center;
    border-radius: 30px;
    background: #fff7f5;
    white-space: nowrap;
    box-shadow: 0px 61px 17px 0px rgba(148, 148, 148, 0.00), 0px 39px 16px 0px rgba(148, 148, 148, 0.01), 0px 22px 13px 0px rgba(148, 148, 148, 0.05), 0px 10px 10px 0px rgba(148, 148, 148, 0.09), 0px 2px 5px 0px rgba(148, 148, 148, 0.10);
    transition: box-shadow 0.3s ease 0s;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 172px;
      height: 228px;
      background: url('/image/templates/imgcategory-bgcakes.png') no-repeat;
      user-select: none;
      pointer-events: none;
    }

    &:hover {
      box-shadow: 0 61px 17px 0 rgba(148,148,148,0),0 39px 6px 0 rgba(148,148,148,.01),0 0 10px 0 rgba(148,148,148,.05),0 0 0 1px rgba(148,148,148,.09),0 2px 5px 0 rgba(148,148,148,.1);
    }

    &_image,
    &_body {
      z-index: 1;
    }

    &_image {
      position: absolute;
      top: -160px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
    }

    &_name a {
      color: #000;
      text-align: center;
      font-family: 'Heuristica', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: normal;
      text-transform: uppercase;
      text-shadow: 0 0 #000;

      &:hover {
        color: #f37a45;
      }
    }

    &_note {
      margin-top: 14px;
      margin-bottom: 14px;
      color: #7b544e;
      font-family: 'Copyright House Industries', sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: normal;
    }

    &_link {
      margin: 0 auto;
      width: 159px;
    }
  }

  .container {
    max-width: 1112px;
  }
}

@media (max-width: 991.98px) {
  .imgcategory {
    margin-top: 150px;
    padding-left: 0;
    padding-right: 0;

    &__title {
      font-size: 36px;
    }

    &__subtitle {
      font-size: 28px;
    }
  }
}

@media (max-width: 767.98px) {
  .imgcategory {
    margin-top: 340px;

    .container {
      padding-right: 0;
    }
  }
}

@media (max-width: 449.98px) {
  .imgcategory {

    &__title {
      font-size: 32px;
    }

    &__subtitle {
      font-size: 20px;
    }
  }
}

/* Imgcategory-module end */