.ltr_rtl_about_us {
  margin: 44px auto;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 980px;
}

.ltr_rtl_about_us__content .container:not(:last-child) .ltr_rtl_about_us__item {
  margin-bottom: 0;
}

.ltr_rtl_about_us__image {
  margin-right: -12px;
  border-radius: 20px;
  overflow: hidden;
}

.ltr_rtl_about_us__information_box {
  padding: 70px 0;
}

.rtl {
  &.ltr_rtl_about_us__item {
    margin-bottom: 15px;
  }

  .ltr_rtl_about_us__image {
    margin-left: -12px;
  }

  .ltr_rtl_about_us__information_box {
    margin-left: 43px;
    margin-right: 35px;
    max-width: 400px;
  }
}

.ltr .ltr_rtl_about_us__information_box {
  margin-left: 40px;
  margin-right: 21px;
  max-width: 405px;
}

.ltr_rtl_about_us__title_item {
  margin-bottom: 34px;
  color: #000;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 1;
}

.ltr_rtl_about_us__description_item {
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.8;
}

@media (max-width: 991.98px) {
  .rtl {
    &.ltr_rtl_about_us__item {
      padding: 17px 0;
    }

    .ltr_rtl_about_us__information_box {
      padding: 0 0 20px;
    }

    .ltr_rtl_about_us__image {
      margin-left: 0;
      margin-right: 0;
    }

    .ltr_rtl_about_us__information_box {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }

    .ltr_rtl_about_us__image {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .ltr .ltr_rtl_about_us__information_box {
    margin-left: 0;
    max-width: 100%;
  }

  .ltr_rtl_about_us__information_box {
    padding: 36px 0 20px;
  }

  .ltr_rtl_about_us__title_item {
    margin-bottom: 25px;
    font-size: 54px;
  }
}

@media (max-width: 449.98px) {
  .ltr_rtl_about_us__title_item {
    font-size: 48px;
  }
}

@media (max-width: 319.98px) {
  .ltr_rtl_about_us__title_item {
    font-size: 34px;
  }
}