.sc-megamenu > nav {
  position: relative;
  height: 100%;
}

.sc-megamenu-list {
  position: relative;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  list-style: none;
}

.sc-megamenu-list-item {
  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    padding: 5px;
  }

  &.active > a, &:hover > a {
    color: #f37a45;
  }

  > svg path {
    flex-shrink: 0;
    transition: all 0.2s ease 0s;
  }

  &.active > svg path, &:hover > svg path {
    stroke: #f57c27;
  }
}

.sc-megamenu-child {
  position: absolute;
  top: 0;
  left: 0;
}

.sc-megamenu-child-narrow {
  right: auto;
  bottom: 0;
  border-radius: 10px;

  ul {
    max-height: calc(100% - 56px);
    overflow-y: auto;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      flex-grow: 1;
    }
  }
}

@media (min-width: 768px) {
  .sc-megamenu-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .sc-megamenu-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 0.3s ease-out;
    visibility: hidden;
    z-index: 1;
  
    &.active {
      background: rgba(0, 0, 0, 0.4);
      visibility: visible;
      z-index: 49;
    }
  }

  .categories_lvl1 {
    max-width: 370px;
    width: 100%;
  }

  .categories_lvl2 {
    left: 100%;
    margin-left: -1px;
    padding-top: 25px;
    padding-bottom: 41px;
    max-width: 430px;
    width: 100%;
    height: fit-content;
  }

  .categories_lvl3, .categories_lvl4 {
    left: 100%;
    margin-left: -1px;
    padding-top: 25px;
    padding-bottom: 41px;
    max-width: 430px;
    width: 100%;
    height: inherit;
  }

  .sc-megamenu__img-bg {
    display: none;
  }

  .sc-megamenu-list-item {
    &.active > .sc-megamenu-child, &:hover > .sc-megamenu-child {
      opacity: 1;
      visibility: visible;
      transform: translateY(0%);
      pointer-events: all;
    }
  }

  .sc-megamenu-child-narrow li:not(.sc-menu-list-landings) {
    .sc-megamenu-child {
      top: 0;
    }

    &:hover > .sc-megamenu-child {
      opacity: 1;
      visibility: visible;
    }
  }

  .sc-megamenu-container {
    position: relative;
    width: 1170px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .sc-megamenu {
    position: absolute;
    top: 100%;
    right: calc(100% - 370px);
    left: 235px;
    transform: translateY(100%);
    padding-top: 20px;
    width: fit-content;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0%);
    }
  }

  .sidebar-header {
    display: none;
  }

  .sc-megamenu > nav {
    width: 270px;
  }

  .sc-megamenu-list {
    background: #fff;
  }

  .sc-megamenu.active .sc-megamenu-list {
    height: 100% !important;
  }

  .sc-megamenu-list-item {
    padding: 0 34px 0 29px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > a {
      min-height: 50px;
      display: flex;
      flex-grow: 1;
      align-items: center;
      color: #1e1e1e;
      font-family: 'Manrope', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      cursor: pointer;

      img {
        margin-right: 35px;
        width: 21px;
        height: 21px;
      }
    }
  }

  .sc-megamenu-child {
    top: 13px;
    min-height: 430px;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    pointer-events: none;
    box-shadow: 0px 70px 20px 0px rgba(145, 145, 145, 0), 0px 45px 18px 0px rgba(145, 145, 145, 0.01), 0px 25px 15px 0px rgba(145, 145, 145, 0.05), 0px 11px 11px 0px rgba(145, 145, 145, 0.09), 0px 3px 6px 0px rgba(145, 145, 145, 0.1);
    transition: opacity 0.3s ease-out 0.05s, visibility 0.3s ease-out 0.05s, transform 0.2s ease-out 0.05s;
    transform: translateY(100%);
  }

  .sc-megamenu-child-narrow {
    ul {
      max-height: 100%;
    }

    li:not(.sc-menu-list-landings) {
      padding: 0 34px 0 29px;
    }
  }

  .main-title-category {
    display: none;
  }
}

@media (min-width: 1400px) {
  .sc-megamenu-container {
    width: 1396px;
  }
}

@media (max-width: 1199.98px) {
  .sc-megamenu-wrap.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #00000066;
    display: block;
    transition: background 0.3s;
    z-index: 49;
  }

  .sc-megamenu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 350px;
    width: 100%;
    transform: translateX(-100%);
    background-color: #fff;
    overflow: hidden;
    transition: transform 0.3s ease-out;
    z-index: 50;

    &.active {
      transform: translateX(0);
      height: 100%;
      overflow: hidden;
    }
  }

  .sc-megamenu__img-bg {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 150px;
    width: 100%;
    height: 100%;
    display: block;
    user-select: none;
    pointer-events: none;
  }

  .sidebar-header {
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #fff;
    font-weight: 700;
    font-size: 20px;
    transition: background 0.3s ease-out;
    min-height: 46px;

    button {
      width: 46px;
      height: 46px;
      background-color: transparent;
    }

    svg {
      width: 16px;
      min-height: 16px;
    }
  }

  .sc-megamenu-list {
    padding-bottom: 65px;
    height: 100%;
    overflow: auto;
  }

  .sc-megamenu-list-item {
    padding: 7px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categories_lvl1 .sc-megamenu-list-item.link-hard:first-child a {
    color: #f1223d;
  }

  .sc-megamenu-list-item img {
    margin-right: 10px;
    width: 28px;
    height: 28px;
  }

  .sc-megamenu-child {
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
    background-color: #f2f4f8;
    z-index: 50;
    transform: translateX(100%);
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;

    &.active {
      transform: translateX(0);
    }
  }

  .main-title-category {
    padding: 0 20px;
    height: 56px;
    line-height: 1;
    display: flex;
    align-items: center;
    background-color: #fff;
  }

  .prev-catalog {
    margin-right: 5px;
  }

  button.btn.btn-buy.no-product {
    margin-right: 0;
    margin-bottom: 9px;
    width: 100%;
    color: #000;
    background-color: #fff;
    pointer-events: none;
    cursor: default;
  }
}