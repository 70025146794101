/* Footer start */

footer {
  position: relative;
  margin-top: 45px;
  padding-top: 55px;
  padding-bottom: 60px;
  flex: 1 1 auto;
  color: #fff;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  background-color: #f37a45;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    content: '';
    border-radius: 50%;
    filter: blur(150px);
    user-select: none;
    pointer-events: none;
  }

  &::before {
    top: -33px;
    left: -258px;
    width: 830px;
    height: 830px;
    background: rgba(255, 255, 255, 0.10);
    
  }

  &::after {
    top: -287px;
    right: -196px;
    width: 783px;
    height: 783px;
    background: rgba(159, 0, 0, 0.20);
  }

  a {
    color: rgba(255, 255, 255, 0.78);
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .phone {
    line-height: 1.4;
  }

  .extra {
    font-size: 12px;
  }
}

.footer__columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  gap: 12px;

  &,
  .column-item {
    position: relative;
    z-index: 1;
  }

  .footer-logo {
    margin-bottom: 34px;
  }
}

.footer-messenger {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  user-select: none;


  .footer-item {
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  .footer-item a img {
    width: 48px;
  }
}

.footer-title {
  margin-top: 6px;
  margin-bottom: 10px;
  color: #fff;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.3;
}

.footer-pay-icons {
  margin-top: 12px;
  user-select: none;

  .item_icon {

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    img {
      width: 180px;
    }
  }
}

/* Footer end */