/* About_us-module start */

.about_us {
  position: relative;
  margin-top: 180px;
  z-index: 1;
}

.about_us-wrap {
  position: relative;
  z-index: 1;

  .swiper-container {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
}

.about_us-title {
  margin-bottom: 31px;
  color: #000;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 1;
  text-align: center;
}

.about_us-cards {
  padding: 57px 72px 44px 122px;
  border-radius: 20px;
  background: #fdfdfd;
  box-shadow: 0px 237px 66px 0px rgba(168, 168, 168, 0.00), 0px 152px 61px 0px rgba(168, 168, 168, 0.01), 0px 85px 51px 0px rgba(168, 168, 168, 0.05), 0px 38px 38px 0px rgba(168, 168, 168, 0.09), 0px 9px 21px 0px rgba(168, 168, 168, 0.10);
}

.about_us--slide {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 80px;
    height: 95px;
  }
}

.about_us--content {
  margin-top: 24px;
  text-align: center;
}

.about_us--title {
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

.about_us-card-title {
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

.swiper-pagination-about-us {
  margin-top: 31px;
  text-align: center;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #ffe9da;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #f37a45;
  }

  &.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px;
    opacity: 1;
  }
}

@media (max-width: 991.98px) {
  .about_us {
    margin-top: 80px;
  }

  .about_us-title {
    margin-bottom: 22px;
    font-size: 54px;
  }

  .about_us-cards {
    padding: 75px 85px;
  }
}

@media (max-width: 767.98px) {
  .swiper-pagination-about-us {
    margin-top: 14px;
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 449.98px) {
  .about_us-wrap {

    .swiper-container {
      touch-action: pan-y;
    }
  }

  .about_us--slide {
    width: 100%;
  }

  .about_us-title {
    font-size: 48px;
  }
}

@media (max-width: 319.98px) {
  .about_us-title {
    font-size: 34px;
  }

  .about_us-cards {
    padding: 15px 25px;
  }
}

/* About_us-module end */