@supports (overflow: clip) {
  .wrapper {
    overflow: clip;
  }
}

@media (min-width: 576px) {
  .col-6 {
    margin-bottom: 16px;
  }

  .card {
    margin-left: -3px;
    margin-right: -3px;
  }

  .row-cols-custom > .col {
    padding: 8px;
  }
}

@media (min-width: 1200px) {
  .footer__columns .catalog, .footer__columns .navigation, .footer__columns .contacts {
    margin-left: auto;
    margin-right: auto;
  }

  .pays {
    margin-left: auto;
  }
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1470px;
  }

  .header__burger-btn,
  .header__burger-menu-wrap,
  .header__burger-menu__img-mob,
  .header__burger-menu__second-img-mob {
    display: none;
  }

  .product-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1499.98px) {
  .header-informations li .popup_call_phone, .header-informations li a {
    font-size: 14px;
  }
}

@media (max-width: 1439.98px) {
  .product-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1399.98px) {
  .logo-catalog .catalog {
    margin-left: 0;
  }

  .header__burger-btn {
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
  }

  .header__burger-menu {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 350px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    transform: translateX(-100%);
    transition: transform .3s ease-out;
    overflow: auto;
    z-index: 110;

    &.active {
      transform: translateX(0);
    }
  }

  .header__burger-close-wrap {
    margin-left: auto;
  }

  .header__burger-close {
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .header__burger-menu-wrap.active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #00000066;
    display: block;
    transition: background 0.3s;
    z-index: 49;
  }

  .header__burger-menu__img-mob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }

  .header__burger-menu__second-img-mob {
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: url('/image/templates/cake-menu-bg.png') no-repeat;
      background-position-x: right;
      background-position-y: bottom;
      background-size: 100%;
      user-select: none;
      pointer-events: none;
    }
  }

  .header-informations,
  .socials-pay-icons {
    position: relative;
    flex-direction: column;
  }

  .header-informations li {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .socials-pay-icons {
    margin-top: 10px;
    margin-right: auto;
  }

  .header-pay-icons {
    margin-top: 12px;
    margin-bottom: 20px;
    margin-left: 0;
  }
}

@media (max-width: 1049.98px) {
  footer {
    padding-top: 49px;

    .container > .column-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index: 1;
    }
  }

  .footer__columns {
    position: relative;
  }

  .footer-pay-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 991.98px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .breadcrumb,
  .pagination {
    justify-content: unset;
  }

  .product-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }

  .pagination>li>a,
  .pagination>li>span {
    min-width: 32px;
    height: 32px;
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  .card-title {
    min-height: 72px;
  }

  h1 {
    font-size: 34px;
  }

  .item-product-image {
    height: 200px;
  }
}

@media (max-width: 649.98px) {
  .modal {

    &.in .modal-dialog {
      top: 50%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .modal-title {
    font-size: 20px;
  }

  footer {
    position: relative;
    padding-top: 49px;
    padding-bottom: 25px;

    &::before,
    &::after {
      width: 386px;
      height: 386px;
      filter: blur(50px);
    }

    &::before {
      left: unset;
      top: 20px;
      right: -115px;
      background: rgba(255, 255, 255, 0.2);
    }

    &::after {
      top: unset;
      right: unset;
      bottom: 44px;
      left: -75px;
    }
  }

  .footer-messenger {
    margin: 0;
  }

  .footer__icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    user-select: none;
    pointer-events: none;
  }

  .footer__columns {
    grid-template-columns: 1fr;

    .footer-logo {
      margin-bottom: 20px;
    }

    .column-item.catalog,
    .column-item.contacts {
      display: flex;
      justify-content: space-between;
    }
  }

  .column-item .catalog-wrap,
  .column-item .contacts-wrap {
    padding-right: 15px;
  }
}

@media (max-width: 579.98px) {
  .product-grid {
    grid-template-columns: 1fr 1fr;
  }

  .card-title a {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .col-6 {
    margin-bottom: 12px;
  }

  .card {
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (max-width: 449.98px) {
  .modal-title {
    font-size: 18px;
  }

  .pagination_wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 389.98px) {
  header a {
    font-size: 14px;
  }

  .header-informations li a {
    font-size: 14px;
  }

  footer {
    padding-top: 30px;

    a {
      font-size: 14px;
    }
  }

  .footer-title {
    font-size: 18px;
  }

  .footer-pay-icons {

    .item_icon {

        img {
        width: 150px;
      }
    }
  }

  .footer-item a img {
    width: 38px;
  }
}

@media (max-width: 349.98px) {
  button#show_categories_btn {
    padding: 10px;
    width: 100%;

    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
}

@media (max-width: 319.98px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
}