#product-category {
  position: relative;
}

#product-category .page_top {
  position: relative;
  padding: 34px 10px 23px;
  width: 100%;
  height: 99px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiper-scrollbar {
  background: #efefef;
  z-index: 1;
}

.swiper-scrollbar-drag {
  background: #d3d3d3;
}

#product-category {
  position: relative;
}

.category__image-left {
  position: absolute;
  top: 130px;
  left: -180px;
  width: 20%;
  rotate: 25deg;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.subcategory__list-container {
  white-space: nowrap;
  overflow-x: auto;
}

.subcategory__list-wrap {
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  overflow: hidden;

  .subcategory__item:not(:last-child) {
    margin-right: 10px;
  }
}

.subcategory__item {
  padding: 5px 15px 5px 5px;
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  background: #fff;
  user-select: none;
  cursor: pointer;
  border: 1px solid #f4f2ef;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;

  &:hover {
    background-color: #f37a45;
    border-color: #fff;

    .subcategory__name {
      color: #fff;
    }
  }
}

.subcategory__link {
  display: flex;
  align-items: center;
}

.subcategory__img {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 40px;
  background-color: #fff;
  overflow: hidden;

  img {
    width: 40px;
  }
}

.subcategory__name {
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.category__title-wrap {
  margin-top: 35px;
  margin-bottom: 22px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.category__title {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 30px;
  padding-bottom: 20px;
  color: #000;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 1;
  text-transform: uppercase;
}

.category-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination_wrapper {
  margin-top: 76px;
  margin-bottom: 123px;
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
}

.category__description {
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.8;
}

@media (max-width: 991.98px) {
  .category__image-left {
    top: 25px;
    left: -120px;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .category__image-left {
    display: none;
  }

  .category__title-wrap {
    flex-direction: column;
    align-items: normal;
  }

  .category__title {
    margin-right: 0;
    margin-bottom: 20px;
    padding-bottom: 0;
    font-size: 32px;
    text-align: center;
  }
}