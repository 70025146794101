/* Why_us-module start */

.why_us {
  padding-top: 64px;
  padding-bottom: 81px;
}

.why_us__top {
  margin-bottom: 85px;
  display: flex;
  justify-content: space-between;
}

.why_us__top-info {
  margin: auto 88px auto 0;
  max-width: 546px;
}

.why_us__img {
  user-select: none;
  pointer-events: none;
}

.why_us__title-wrap {
  margin-bottom: 12px;
  max-width: 384px;
}

.why_us__title {
  color: #000;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 1;
}

.why_us__subtitle {
  margin-top: -12px;
  color: #ee713f;
  font-family: 'Copyright House Industries', sans-serif;
  font-weight: 400;
  font-size: 42px;
  line-height: 1;
  text-align: end;
}

.why_us__title-description {
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 1.4;
}

.why_us__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 111px;
  grid-column-gap: 30px;

  .card {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    padding: 53px 24px 27px 27px;
    min-height: 230px;
    height: 100%;
    background: #fff6f2;
    border-radius: 20px;
    overflow: unset;
  }
}

.why_us {
  .number-card {
    position: absolute;
    top: -50px;
    left: 0;
    color: #ee713f;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 96px;
    line-height: normal;
    text-transform: uppercase;
    user-select: none;
  }

  .title-card {
    margin-bottom: 4px;
    color: #000;
    text-align: center;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
  }

  .description-card {
    color: #000;
    font-family: 'Manrope', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
  }
}

@media (max-width: 1399.98px) {
  .why_us__top-info {
    margin-right: 20px;
  }
}

@media (max-width: 991.98px) {
  .why_us__title-wrap {
    margin-bottom: 20px;
    max-width: 284px;
  }

  .why_us__title {
    font-size: 48px;
  }

  .why_us__subtitle {
    font-size: 32px;
  }

  .why_us__cards {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 54px;
    grid-column-gap: 10px;
  }
}

@media (max-width: 767.98px) {
  .why_us__top {
    flex-direction: column;
  }

  .why_us__title-wrap {
    max-width: 100%;
  }

  .why_us__title {
    text-align: center;
  }

  .why_us__subtitle {
    margin-top: 0;
    text-align: center;
  }

  .why_us__title-description {
    text-align: center;
  }
}

@media (max-width: 579.98px) {
  .why_us__top {
    margin-bottom: 67px;
  }

  .why_us__cards {
    grid-template-columns: 1fr;
  }

  .why_us .number-card {
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 64px;
  }
}

/* Why_us-module end */