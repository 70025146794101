/* Cakes_order-module start */

.cakes_order {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;

  &__title-wrap {
    margin-bottom: 25px;
    text-align: center;
  }

  &__title {
    margin-bottom: 5px;
    color: #000;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 1;
    text-transform: uppercase;
  }

  &__subtitle {
    color: #f37a45;
    font-family: 'Copyright House Industries', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: normal;
  }
}

.cakes_order, .cakes_order__slide {
  min-height: 600px;
}

.cakes_order__wrap {
  position: relative;

  .swiper-container {
    position: relative;
  }
}

.cakes_order__slide {
  user-select: none;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.cakes_order-btn {
  padding: 10px 18px;
  min-width: 370px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #f37a45;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  border-radius: 10px;
  border: 1px solid #f37a45;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s;

  &:hover {
    color: #f37a45;
    border-color: #f37a45;
    background-color: #fff;
  }
}

.swiper-pagination-wrap {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 34px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  .swiper-pagination {
    margin-top: 0;
    margin-bottom: 24px;
    text-align: center;
  }
}

.cakes_order__buttons {
  display: none;
  position: relative;
  bottom: 150px;
  left: 0;
  z-index: 2;

  .swiper-button-prev-main, .swiper-button-next-main {
    width: 41px;
    height: 41px;
    backdrop-filter: blur(50px);
    border-radius: 50%;
    background: rgba(30, 30, 30, 0.2);
    cursor: pointer;
  }
}

@media (max-width: 991.98px) {
  .cakes_order__title {
    font-size: 36px;
  }

  .cakes_order__title {
    font-size: 28px;
  }

  .cakes_order,
  .cakes_order__slide {
    min-height: 503px;
  }

  .swiper-pagination-wrap {
    bottom: -25px;
    margin-bottom: 0;

    .swiper-pagination {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 449.98px) {
  .cakes_order__title {
    font-size: 24px;
  }

  .cakes_order__title {
    font-size: 20px;
  }

  .cakes_order-btn {
    min-width: 100%;
  }
}

/* Cakes_order-module end */