::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #f37a45;
  border-radius: 30px;
}

// ::selection {
//   color: #fff;
//   background: #d6d6ea;
// }

:focus-visible {
  outline: 0;
}

.custom-tooltip {
  --bs-tooltip-bg: #fff;
  --bs-tooltip-color: #f37a45;
  --bs-tooltip-font-size: 12px;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  width: 100vw;
  height: 100%;
}

body {
  width: 100%;
  color: #7b7b7b;
  background-color: #fff;
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.8;
  overflow-x: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  .lock & {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }
}

.wrapper {
  padding-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Card = START */
.row-cols-custom {
  margin-left: -8px;
  margin-right: -8px;
}

.card {
  border: 0;
  border-radius: 10px;
  overflow: hidden;
  transition: color .3s ease-out;

  &:hover .item-product-information::before {
    transform: scale(6);
  }

  &:hover .item-product-information a,
  &:hover .item-product-weight {
    color: #fff;
    transition: color .3s ease-out;
  }

  &:not(:hover) .item-product-information a,
  &:not(:hover) .item-product-weight {
    transition: color .3s ease-out;
  }
}

.item-product-information {
  position: relative;
  height: 100%;
  padding: 8px 30px 15px 11px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    right: -63px;
    bottom: -72px;
    width: 116px;
    height: 116px;
    background-color: #f37a45;
    border-radius: 50%;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform .3s ease-out;
  }
}

.card-title,
.item-product-weight {
  position: relative;
}

.card-title {
  margin-bottom: 6px;
  min-height: 57px;

  a {
  color: #7b544e;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  }
}

.go-arrow {
  position: absolute;
  right: 14px;
  bottom: 14px;
  width: 11px;
  height: 11px;
}

.item-product-weight {
  color: #8c8c8c;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.product-grid {
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  overflow-y: hidden;

  .card {
    margin-left: 0;
    margin-right: 2px;
  }
}

.item-product-image {
  height: 300px;
  flex-shrink: 0;

  a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
/* Card = END */

.phone {
  white-space: nowrap;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.right {
  margin-left: auto;
}

small {
  font-size: 70%;
}

.form-control:focus {
  border-color: rgb(251, 194, 235);
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  // -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(251, 194, 235, .6);
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(251, 194, 235, .6);
}

label {
  user-select: none;
}

textarea {
  resize: vertical;
}

select {
  user-select: none;
  cursor: pointer;
}

.modal {
  padding: 20px;

  &.in .modal-dialog {
    top: calc(50% - 83px);
    transform: translateY(-50%);
  }
}

.modal-content {
  padding: 20px;
}

.modal-header .close {
  margin-top: -35px;
  margin-right: -35px;
  padding: 10px;
  width: 50px;
  height: 50px;
  font-size: 30px;
}

.modal-title {
  color: #000;
  font-family: 'Craftwork Grotesk', sans-serif;
  font-weight: 700;
  font-size: 32px;
}

#error-not-found {

  h1,
  p {
    margin-bottom: 15px;
  }

  .delivery__btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* margin: 0; */
  font-weight: inherit;
  font-size: inherit;
}

/* Override the bootstrap defaults */
h1 {
  font-size: 48px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 21px;
}

h4 {
  font-size: 15px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10.2px;
}

img {
  height: auto;
  max-width: 100%;
  display: block;
  border: none;
}

hgroup,
ul,
ol,
dd,
figure,
pre,
table,
fieldset,
hr {
  margin: 0;
}

address {
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

ol,
ul {
  padding-left: 0;
}

button {
  cursor: pointer;
}

a {
  color: #7b544e;
  text-decoration: none;
  transition: color 0.3s ease 0s;
  /* text-decoration-skip-ink: auto; */

  &:hover,
  &:link,
  &:visited {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  &:active {
    outline: none;
  }

  &:hover {
    color: #f37a45;
    text-decoration: none;
  }
}

/* Breadcrumb */
.breadcrumb {
  position: relative;
  margin: 8px 0;
  padding: 8px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: center;
  overflow: auto;
  z-index: 1;

  >li:not(:last-child) {
    padding-right: 18px;
  }

  >li {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
    white-space: nowrap;

    +li::before {
      content: '';
      padding: 0;
    }

    &:not(:last-child)::after {
      content: '/';
      position: absolute;
      top: -5px;
      right: 11px;
      width: 0;
      height: 0;
      display: block;
      color: #8c8c8c;
    }

    a {
      color: #8c8c8c;
      font-family: 'Manrope', sans-serif;
      font-weight: 300;
      font-size: 14px;
      line-height: 1;

      &:hover {
        color: #000;
      }
    }
  }

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
}

/* content */
#content {
  min-height: 600px;
}

/* Cart */
.mini-cart {
  position: relative;
}

#cart {
  position: relative;
  user-select: none;
  cursor: pointer;
  z-index: 1;
}

#cart-total {
  position: absolute;
  top: -7px;
  right: -8px;
  width: 15px;
  height: 15px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #7d965e;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.1;
  text-transform: uppercase;
  border-radius: 50%;
}

.container-cart {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 80px 22px 0px rgba(0, 0, 0, 0.00), 0px 51px 20px 0px rgba(0, 0, 0, 0.01), 0px 29px 17px 0px rgba(0, 0, 0, 0.05), 0px 13px 13px 0px rgba(0, 0, 0, 0.09), 0px 3px 7px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;
  transition: 1s;
  cursor: auto;

  &.in {
    display: block;
    height: auto;
    transition: 1s;
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    from {
      padding: 0px 15px;
      opacity: 0;
    }

    to {
      padding: 32px 15px;
      opacity: 1;
    }
  }
}

.title-mini-cart {
  margin-bottom: 29px;
  color: #222b45;
  font-family: 'Craftwork Grotesk', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
}

.list-product-cart {
  padding-right: 10px;
  max-height: 50vh;
  overflow: auto;
}

.item-product-cart {
  width: 100%;
  display: flex;
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #edf1f7;
}

.information-product-cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-product-cart {
  width: 100%;
  display: flex;
}

.bottom-product-cart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .total-product {
    color: #222b45;
    font-family: 'Craftwork Grotesk', sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.3;
  }
}

.image-product-cart {
  margin-right: 12px;
  min-width: 80px;
  width: 80px;
  border-radius: 5px;
  overflow: hidden;
}

.delete-product {
  margin-left: auto;

  button {
    padding: 7.5px 12px;
    min-height: auto;
    background-color: transparent;
    cursor: pointer;
  }
}

.name-product {
  margin-top: 17px;
  color: #0d0802;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;

  a {
    display: block;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    text-align: left;
  }
}

.item-totals {

  &.total {
    margin-top: 15px;
    margin-bottom: 29px;
    display: flex;
    justify-content: space-between;
  }

  .name-total {
    color: #222b45;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
  }

  .value-total {
    color: #222b45;
    font-family: 'Craftwork Grotesk', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
  }
}

.group-cart-button {
  display: flex;
  flex-direction: column;
  user-select: none;

  a.btn-checkout {
    margin-bottom: 22px;
    height: 55px;
    color: #fff;
    border-radius: 27.5px;
    background: linear-gradient(90deg, #F6D365 0%, #FDA085 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    text-transform: none;
    -webkit-transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
    -o-transition: color 0.2s ease 0s, background-color 0.2s ease 0s;
    transition: color 0.2s ease 0s, background-color 0.2s ease 0s;

    &:hover {
      color: #fff;
    }
  }

  button[type="button"] {
    color: #222B45;
    -webkit-text-fill-color: #222B45;
  }

  button.btn-exit {
    font-size: 16px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

.quantity-product {
  .qty-spinedit {
    display: flex;

    .quantity {
      color: #c5cee0;
      text-align: center;
      font-family: 'Manrope', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.4;
      background-color: transparent;
    }
  }

  .quantity {
    border: none;
    text-align: center;
  }

  input {

    &.qty-minus,
    &.qty-plus {
      width: 30px;
      height: 30px;
      border-radius: 11px;
      color: #8992a3;
      background-color: transparent;
      font-size: 24px;
      line-height: 1;
      border: 1px solid #edf1f7;
      cursor: pointer;
    }
  }
}

/* Pagination */
.pagination {
  margin: 0;
  border-radius: 0;
  justify-content: end;

  > {
    li {
      &.text_first a,
      &.text_last a,
      &.text_prev a,
      &.text_next a {
        color: #121212;
        background-color: transparent;
        font-size: 22px;
      }

      > {
        a, span {
          margin-left: 7px;
          margin-right: 7px;
          margin-bottom: 12px;
          min-width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #121212;
          background-color: #ededed;
          font-family: 'Manrope', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
          border-radius: 10px;
          user-select: none;
          transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
        }
      }

      &:last-child > {
        a, span {
          border-radius: 10px;
        }
      }

      &:first-child > {
        a, span {
          border-radius: 10px;
        }
      }
    }

    .active > {
      a {
        color: #fff;
        background-color: #f37a45;
        border-color: transparent;
        cursor: default;

        &:focus, &:hover {
          color: #fff;
          background-color: #f37a45;
          border-color: transparent;
          cursor: default;
        }
      }

      span {
        color: #fff;
        background-color: #f37a45;
        border-color: transparent;
        cursor: default;

        &:focus, &:hover {
          color: #fff;
          background-color: #f37a45;
          border-color: transparent;
          cursor: default;
        }
      }
    }

    li > {
      a {
        &:focus, &:hover {
          color: #fff;
          background-color: #f37a45;
        }
      }

      span {
        &:focus, &:hover {
          color: #fff;
          background-color: #f37a45;
        }
      }
    }
  }

  &_block {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 2px;
    }
  }
}

/* Swiper */

.swiper-pagination {
  position: relative;
  margin-top: 39px;
  text-align: center;

  &.swiper-pagination-bullets .swiper-pagination-bullet,
  &.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px;
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: #ffe9da;
    opacity: 1;

    &-active {
      background: #f37a45;
    }
  }
}