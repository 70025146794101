/* Franchise-module start */

.franchise {
  padding-left: 0;
  padding-right: 0;

  &__img {
    margin-bottom: 48px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
      min-width: 370px;
    }
  }

  &__title {
    margin-bottom: 27px;
    color: #000;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 1;
    text-transform: uppercase;
  }

  &__description {
    margin-bottom: 21px;
    max-width: 710px;
    color: #868686;
    text-align: center;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
  }
}

@media (max-width: 991.98px) {
  .franchise__img {
    margin-bottom: 27px;
  }
}

@media (max-width: 449.98px) {
  .franchise {

    .btn {
      min-width: 100%;
    }
  }
}

/* Franchise-module end */