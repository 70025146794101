/* Button start */

.btn {
  padding: 10px 18px;
  min-height: 50px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #f37a45;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  border-radius: 10px;
  border: 1px solid #f37a45;
  word-wrap: break-word;
  user-select: none;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    color: #f37a45;
    border-color: #f37a45;
    background-color: transparent;
  }
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: #f37a45;
  background-color: transparent;
  border-color: #f37a45;
}

/* Button end */