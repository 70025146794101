/* Common */
@import '_base';
@import '_buttons';
@import '_header';
@import '_footer';
@import '_media_queries';
@import '_menu_catalog';

/* Category Page */
@import '_category';

/*--- Modules ---*/

/* Main Page */
@import 'modules/_main_banner';
@import 'modules/_imgcategory';
@import 'modules/_cakes_order';
@import 'modules/_download_app';
@import 'modules/_collaboration';
@import 'modules/_counter';
@import 'modules/_franchise';

/* Spivpracya Page */
@import 'modules/_why_us';

/* Pro Nas Page */
@import 'modules/_ltr_rtl';
@import 'modules/_about_us';
@import 'modules/_ltr_rtl_about_us';