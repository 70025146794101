/* Download_app-module start */

.download_app {
  position: relative;
  padding-top: 166px;
  padding-bottom: 250px;
  padding-left: 0;
  padding-right: 0;
}

.download_app__image-bg {
  position: absolute;
  content: '';
  top: 67%;
  left: 78%;
  width: 30%;
  height: 100%;
  display: block;
  user-select: none;
  pointer-events: none;
}

.download_app__image-bg-second {
  display: none;
}

.download_app__image {
  position: absolute;
  top: 56%;
  right: 0;
  transform: translateY(-50%);
  user-select: none;
  pointer-events: none;

  img {
    max-width: 1112px;
  }

  &.left {
    left: 0;
    padding-right: calc(48% - 300px);
  }
}

.download_app__wrap {
  max-width: 380px;
  text-align: center;
}

.download_app__info {
  position: relative;
  padding-top: 70px;
  padding-right: 58%;
  display: flex;
  flex-direction: column;

  &.right {
    padding-left: 66%;
    padding-right: unset;
  }

  .container {
    position: relative;
  }
}

.download_app__title-wrap {
  position: relative;
  margin-bottom: 74px;
  z-index: 1;
}

.download_app__title {
  color: #000;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 1;
  text-transform: uppercase;
}

.download_app__subtitle {
  color: #000;
  text-align: center;
  font-family: 'Copyright House Industries', sans-serif;
  font-weight: 400;
  font-size: 36px;
  line-height: 1;
}

.download_app__download {
  margin-bottom: 34px;
  color: #f37a45;
  font-family: 'Copyright House Industries', sans-serif;
  font-weight: 400;
  font-size: 36px;
  line-height: normal;
}

.download_app__description {
  margin-bottom: 26px;
  color: #7b7b7b;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8;
}

.download_app-pay-icons {
  user-select: none;

  .item_icon {
    display: block;
  }

  .item_icon a {
    display: inline-block;
  }

  .item_icon:not(:last-child) {
    margin-bottom: 27px;
  }

  .item_icon img {
    width: 240px;
  }
}

@media (max-width: 1199.98px) {
  .download_app__image.left {
    left: -20%;
  }

  .download_app__info.right {
    padding-left: 50%;
  }
}

@media (max-width: 991.98px) {
  .download_app__image-bg {
    display: none;
  }

  .download_app {
    padding-top: 220px;
  }

  .download_app__image-bg-second {
    position: absolute;
    content: '';
    right: -388px;
    bottom: -590px;
    width: 80%;
    height: 100%;
    display: block;
    user-select: none;
    pointer-events: none;
  }

  .download_app__image.left {
    left: -40%;
    padding-right: calc(68% - 300px);
  }

  .download_app-pay-icons .item_icon img {
    width: 180px;
  }
}

@media (max-width: 767.98px) {
  .download_app {
    padding-top: 100px;
  }

  .download_app__image.left {
    left: -60%;
    padding-right: calc(88% - 300px);
  }

  .download_app__title-wrap {
    text-align: center;
  }
}

@media (max-width: 649.98px) {
  .download_app {
    padding-top: 85px;
    padding-bottom: 430px;
  }

  .download_app__image-bg-second {
    right: -300px;
    bottom: -489px;
    width: 90%;
  }

  .download_app__image.left {
    top: 135%;
    left: -560px;
  }
  
  .download_app__title {
    font-size: 48px;
  }

  .download_app__subtitle {
    font-size: 32px;
  }

  .download_app__download {
    margin-bottom: 25px;
    font-size: 24px;
  }

  .download_app-pay-icons .item_icon:not(:last-child) {
    margin-bottom: 16px;
  }

  .download_app__info.right {
    padding-top: 0;
  }
}

@media (max-width: 489.98px) {
  .download_app__image-bg-second {
    right: -212px;
    bottom: -548px;
  }
}

/* Download_app-module end */