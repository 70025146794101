/* Collaboration-module start */

.collaboration {
  position: relative;
  margin-top: 87px;
  height: 500px;
  display: flex;
  align-items: center;
  background: url('/image/templates/collaboration-bg.png') no-repeat;
  background-size: cover;

  &::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODM3IiBoZWlnaHQ9IjI4MiIgdmlld0JveD0iMCAwIDgzNyAyODIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxMTYzIiBoZWlnaHQ9IjI4MiIgcng9IjE0MSIgZmlsbD0iI0YzN0E0NSIvPgo8L3N2Zz4K") no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.collaboration__avatar {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    bottom: 0;
    right: 333px;
    user-select: none;
    pointer-events: none;
  }
}

.collaboration__info {
  position: relative;
  padding-left: 20%;
  padding-right: 45%;
  z-index: 1;

  a {
    color: #000;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;

    svg {
      margin-bottom: 2px;
      margin-left: 7px;
      flex-shrink: 0;
      transition: transform 0.2s ease 0s;
    }

    &:hover {
      color: #f37a45;
    }

    &:hover svg {
      transform: translate(0, 0) rotate(-136deg);
    }

    svg path {
      transition: stroke 0.3s ease 0s;
    }

    &:hover svg path {
      stroke: #f57c27;
    }
  }
}

.collaboration__title {
  margin-bottom: 5px;
  color: #000;
  font-family: 'Heuristica', sans-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 1;
}

.collaboration__subtitle {
  margin-bottom: 26px;
  color: #000;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1;
}

.collaboration__description {
  margin-bottom: 5px;
  color: #868686;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
}

@media (max-width: 1649.98px) {
  .collaboration__info {
    padding-left: 10%;
    padding-right: 55%;
  }
}

@media (max-width: 1649.98px) {
  .collaboration__info {
    padding-left: 0;
    padding-right: 70%;
  }
}

@media (max-width: 1249.98px) {
  .collaboration::before, .collaboration__avatar {
    right: -300px;
  }
}

@media (max-width: 849.98px) {
  .collaboration {
    margin-top: 0;
    height: 717px;
    align-items: baseline;
    background-size: cover;

    &::before {
      background-position-x: unset;
    }
  }

  .collaboration__info {
    padding-top: 94px;
    padding-right: 40%;
  }

  .collaboration__title {
    font-size: 56px;
  }

  .collaboration__avatar img {
    right: 170px;
  }
}

@media (max-width: 549.98px) {
  .collaboration {
    background-position: right;

    &::before {
      background-position-x: unset;
      right: -110px;
    }
  }
}

/* Collaboration-module end */