/* Counter-module start */

.counter {
  padding-top: 71px;
  padding-bottom: 83px;
  padding-left: 0;
  padding-right: 0;

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__img {
    margin-bottom: 24px;
  }

  &__title {
    margin-bottom: 20px;
    color: #101828;
    font-family: 'Copyright House Industries', sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.2;
    letter-spacing: -0.72px;
  }

  &__img-text {
    margin-bottom: 20px;
    width: 334px;
  }

  &__description {
    margin-bottom: 48px;
    max-width: 602px;
    color: #667085;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 330px);
    text-align: center;
  }

  &__item-counter-number {
    margin-bottom: 12px;
    color: #f37a45;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: -1.2px;
  }

  &__counter-text {
    color: #101828;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1019.98px) {
  .counter__items {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
  }
}

@media(max-width: 991.98px) {
  .counter {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media (max-width: 679.98px) {
  .counter__description {
    margin-bottom: 10px;
  }

  .counter__items {
      grid-template-columns: 1fr;
      justify-content: center;
  }
}

/* Counter-module end */