/* Header start */

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 70px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 64px 18px 0px rgba(176, 176, 176, 0.00), 0px 41px 16px 0px rgba(176, 176, 176, 0.01), 0px 23px 14px 0px rgba(176, 176, 176, 0.05), 0px 10px 10px 0px rgba(176, 176, 176, 0.09), 0px 3px 6px 0px rgba(176, 176, 176, 0.10);
  z-index: 100;

  a {
    color: #1e1e1e;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    text-shadow: 0 0 #1e1e1e;
  }
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-catalog {
  display: flex;
  align-items: center;

  .catalog {
    margin-left: 43px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

button#show_categories_btn {
  padding: 10px 18px;
  width: 146px;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 22.5px;
  background: linear-gradient(90deg, #f16222 0%, #e78d73 100%);

  &:hover::before {
    opacity: 1;
  }

  & span {
    padding-top: 2px;
    color: #fff;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    white-space: nowrap;
    user-select: none;
  }

  & svg {
    margin-right: 14px;
    flex-shrink: 0;
  }
}

.header-informations {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;

  li {
    padding-left: 15px;
    padding-right: 15px;
  }

  li .popup_call_phone,
  li a {
    color: #1e1e1e;
    background-color: transparent;
    font-family: 'Heuristica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    white-space: nowrap;
    text-shadow: 0 0 #1e1e1e;
    transition: color .3s ease 0s;

    &:hover {
      color: #f37a45;
    }
  }
}

.socials-pay-icons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.header-socials {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style-type: none;
  user-select: none;

  .header-item {
    margin-bottom: 8px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .header-item a img {
    width: 29px;
  }
}

.header-pay-icons {
  margin-left: 19px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  user-select: none;

  .item_icon {
    margin-bottom: 3px;

    &:not(:last-child) {
      margin-right: 3px;
    }

    img {
      width: 111px;
    }
  }
}

/* Header end */